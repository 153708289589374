/**
 * @fileoverview Project Management Toolkit (PMT) Introduction
 *
 * @description The **Project Management Toolkit (PMT)** represents a significant advancement in managing project proposals.
 * It offers a more comprehensive solution compared to the previous P3 system, addressing its limitations
 * and introducing new features that make project management more efficient and organized.
 *
 * ### Key Features of PMT:
 * - **Stage Gates and Gantt Charts**: Provides a structured and visual approach to managing projects, making planning and execution smoother.
 * - **Threaded Communication**: Centralizes discussions and updates, replacing the need for endless email threads and improving team collaboration.
 * - **Real-Time Status Updates**: Offers immediate visibility into the progress of proposals and active projects, ensuring transparency and quick decision-making.
 * - **Financial Management**: Includes tools for tracking project funds and analyzing expenses, helping teams stay within budget.
 * - **Asset Register**: Maintains a comprehensive record of all assets related to the project for better resource management.
 *
 * PMT is designed to ensure that projects are completed on time, within budget, and with optimal resource management.
 *
 * For routing and navigation, you can explore the routing setup in the [Routes Module](./module-Routes.html).
 */

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "./components/auth/AuthContext";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SplashScreen } from "./components/splash-screen";
import { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-tooltip/dist/react-tooltip.css";
import "./index.css";

const successStyles = {
  duration: 3000,
  style: {
    background: "#16a34a",
    color: "white",
  },
  theme: {
    primary: "#16a34a",
    secondary: "white",
  },
  iconTheme: {
    primary: "white",
    secondary: "#16a34a",
  },
};

const errorStyles = {
  duration: 3000,
  style: {
    background: "#dc2626",
    color: "white",
  },
  theme: {
    primary: "#dc2626",
    secondary: "white",
  },
  iconTheme: {
    primary: "white",
    secondary: "#dc2626",
  },
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 2 * 60, // Set the stale time to two minutes.
      cacheTime: 1000 * 10 * 60, // Set the cache time to ten minutes.
    },
  },
});

global.Buffer = global.Buffer || require("buffer").Buffer;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          success: successStyles,
          error: errorStyles,
        }}
      />
      <Suspense fallback={<SplashScreen />}>
        <RouterProvider router={router} />
      </Suspense>
    </QueryClientProvider>
  </AuthProvider>
);
